import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ErrorPageErrorsEnum, FfErrorPageComponent } from '@shared/components/ff-error-page/ff-error-page.component';
import { SharedModule } from '@shared/shared.module';
import { PublicHeaderComponent } from '@shared/components/public-header/public-header.component';

@Component({
    selector: 'app-page-not-found',
    templateUrl: './page-not-found.component.html',
    styleUrls: ['./page-not-found.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [
    SharedModule,
    PublicHeaderComponent,
    FfErrorPageComponent,
  ],
})
export class PageNotFoundComponent {
  error = ErrorPageErrorsEnum.PAGE_NOT_FOUND;
}
