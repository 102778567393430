import { Component, Input } from '@angular/core';
import { RouterLink } from '@angular/router';

@Component({
  selector: 'app-ff-error-page',
  templateUrl: './ff-error-page.component.html',
  styleUrls: ['./ff-error-page.component.scss'],
  imports: [
    RouterLink,
  ],
})
export class FfErrorPageComponent {
  @Input()
  errorType!: ErrorPageErrorsEnum;

  errorsEnum = ErrorPageErrorsEnum;
}

export enum ErrorPageErrorsEnum {
  CONSENT_MISSING = 'consent_missing',
  LOGIN = 'login',
  PAGE_NOT_FOUND = 'page_not_found',
  SESSION_EXPIRED = 'session_expired',
  UNKNOWN = 'unknown',
  USER_DEACTIVATED = 'user_deactivated',
}
