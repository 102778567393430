import { Component, signal } from '@angular/core';
import { FfNgxIconComponent } from '@fagforbundet/ngx-components';

@Component({
  selector: 'app-public-header',
  templateUrl: './public-header.component.html',
  styleUrls: ['./public-header.component.scss'],
  imports: [
    FfNgxIconComponent,
  ],
})

export class PublicHeaderComponent {
  hover = signal(false);
}
