<svg
  width="197"
  height="36"
  viewBox="0 0 197 36"
  fill="none"
  xmlns="http://www.w3.org/2000/svg"
>
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M0.976807 36H48.9768V0H0.976807V36Z"
    fill="#E74133"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M26.4864 29.1777C26.4864 27.0369 27.3421 24.7415 28.8605 22.9865C29.3876 22.376 30.0777 21.739 30.9506 21.1721C30.8586 23.0804 29.895 25.911 26.7493 29.9138C26.6704 29.9111 26.6559 29.9111 26.5666 29.9111C26.5429 29.6667 26.4864 29.4275 26.4864 29.1777ZM10.9114 16.0236C10.1017 16.8152 9.06317 18.3098 9.06317 20.7757C9.06317 21.6386 9.20383 22.6324 9.51275 23.7464H5.27197V26.0392H12.7663L12.1524 24.4719C10.9732 21.4576 11.0955 19.0406 12.5007 17.6702C13.601 16.5958 15.3928 16.3289 16.9571 17.0042C18.6989 17.7548 19.6927 19.4383 19.6927 21.636V21.7205L21.9748 21.7272V21.6756C21.9761 19.3141 23.2144 17.5209 25.2954 16.8773C27.2514 16.2707 29.3074 16.8746 30.2933 18.3455C30.3709 18.4618 30.455 18.6045 30.5339 18.7683C29.2417 19.4674 28.0967 20.37 27.1384 21.4827C25.2638 23.6499 24.2095 26.5109 24.2095 29.2068C24.2095 29.9257 24.2832 30.6327 24.4383 31.3106L24.6394 32.1986L27.8496 32.2105L28.1927 31.785C32.1166 26.9365 33.2603 23.3566 33.2603 20.8272C33.2603 20.555 33.2274 20.3171 33.2011 20.07C34.5223 19.6101 36.1274 19.3485 38.0414 19.3987V26.0392H40.3221V17.2447L39.2771 17.1574C36.8622 16.9526 34.6248 17.2103 32.6333 17.875C32.4913 17.5724 32.3414 17.2936 32.1837 17.0623C30.6417 14.7603 27.5314 13.785 24.6223 14.6849C23.2512 15.1091 21.8433 16.0434 20.8811 17.509C20.1738 16.3553 19.1472 15.4514 17.8576 14.8964C15.4546 13.8603 12.6651 14.3149 10.9114 16.0236Z"
    fill="white"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M13.9388 7.94918C14.0663 8.43946 14.157 8.92444 14.157 9.30107C14.157 9.61955 14.1071 9.86006 14.0032 9.96049C13.6575 10.2882 12.7439 10.1812 11.9643 9.95653V7.94918H13.9388ZM14.6408 6.07935H10.1042V11.2622L10.6761 11.5027C10.9876 11.6349 13.7745 12.7476 15.2797 11.3204C16.656 10.0161 15.7148 7.23168 15.511 6.68723L15.2849 6.07935H14.6408Z"
    fill="white"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M40.0722 7.94918C40.2011 8.43946 40.2905 8.92444 40.2905 9.30107C40.2905 9.61955 40.2405 9.86006 40.1367 9.96049C39.7922 10.2882 38.8773 10.1812 38.0991 9.95653V7.94918H40.0722ZM40.7756 6.07935H36.239V11.2622L36.8095 11.5027C37.1224 11.6349 39.908 12.7476 41.4131 11.3204C42.7895 10.0161 41.8482 7.23168 41.6445 6.68723L41.4197 6.07935H40.7756Z"
    fill="white"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M27.0177 7.64853C27.1689 7.80843 27.2307 8.09652 27.2307 8.43482C27.2307 8.82994 27.1347 9.27661 27.0151 9.69816H25.0183V7.71329C25.9214 7.4741 26.8021 7.41859 27.0177 7.64853ZM23.7616 6.1341L23.1582 6.36008V11.5667H28.3139L28.5518 10.9919C28.6846 10.6774 29.7915 7.87714 28.3704 6.36536C27.073 4.98044 24.3045 5.92795 23.7616 6.1341Z"
    fill="white"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M63.5201 17.1667V19.5242H66.842V21.6201H63.5201V26.0405H60.9778V14.9875H67.0379V17.1667H63.5201Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M74.0901 19.4592C73.9113 18.9174 73.7812 18.41 73.6668 17.9197H73.6339C73.5209 18.41 73.4065 18.9174 73.2277 19.4592L72.3799 21.9793H74.9367L74.0901 19.4592ZM76.2565 26.0402L75.5729 23.9933H71.7791L71.1087 26.0402H68.5847L72.2997 14.9873H75.1825L79.0421 26.0402H76.2565Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M86.7302 26.2215C83.1638 26.2215 81.1433 24.1401 81.1433 20.7849C81.1433 17.2644 83.5371 14.825 87.3651 14.825C88.5548 14.825 89.6945 15.0205 90.5424 15.2835L90.0376 17.608C89.2397 17.2988 88.3103 17.0992 87.2508 17.0992C85.1672 17.0992 83.8802 18.4273 83.8802 20.5893C83.8802 22.6336 84.9542 23.9128 87.0076 23.9128C87.398 23.9128 87.7569 23.8613 88.0487 23.7635V20.1453H90.5911V25.4999C89.5157 25.9426 88.1473 26.2215 86.7302 26.2215Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M97.0073 17.1667V19.5242H100.329V21.6201H97.0073V26.0405H94.4675V14.9875H100.526V17.1667H97.0073Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M108.637 17.0995C107.318 17.0995 106.063 18.1316 106.063 20.4416C106.063 22.5692 106.846 23.9131 108.588 23.9131C109.793 23.9131 111.211 23.0106 111.211 20.5063C111.211 18.281 110.314 17.0995 108.637 17.0995ZM108.442 26.2522C105.037 26.2522 103.376 24.1246 103.376 20.653C103.376 17.0506 105.835 14.7764 108.831 14.7764C111.731 14.7764 113.897 16.4626 113.897 20.3412C113.897 23.9779 111.487 26.2522 108.442 26.2522Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M120.527 17.0014C120.266 17.0014 119.974 17.0199 119.826 17.0345V19.8664C119.958 19.8849 120.217 19.9008 120.461 19.9008C121.537 19.9008 122.22 19.3273 122.22 18.3943C122.22 17.5247 121.715 17.0014 120.527 17.0014ZM122.53 26.0404L119.974 21.7178H119.826V26.0404H117.285V14.9875C118.213 14.9227 119.403 14.9082 120.722 14.9082C123.313 14.9082 124.876 15.8385 124.876 18.1802C124.876 19.6708 123.898 20.8827 122.515 21.2434C122.808 21.6689 123.101 22.0799 123.361 22.4869L125.625 26.0404H122.53Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M132.059 21.3239C131.928 21.3239 131.407 21.3239 131.213 21.3424V23.9444C131.423 23.9774 131.83 23.9933 132.205 23.9933C133.346 23.9933 133.98 23.47 133.98 22.6176C133.98 21.7666 133.246 21.3239 132.059 21.3239ZM132.189 16.9709C131.799 16.9709 131.521 16.9854 131.213 17.0013V19.4249C131.423 19.4407 131.667 19.4407 131.962 19.4407C133.199 19.4407 133.704 18.9333 133.704 18.1008C133.704 17.3964 133.232 16.9709 132.189 16.9709ZM132.173 26.0747C131.504 26.0747 130.267 26.0403 128.67 26.0403V15.0045C129.681 14.957 131.261 14.9226 132.613 14.9226C134.975 14.9226 136.392 15.8569 136.392 17.7057C136.392 18.7364 135.853 19.6707 134.892 20.0962V20.1451C136.017 20.4425 136.7 21.4891 136.7 22.5859C136.7 24.3541 135.414 26.0747 132.173 26.0747Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M144.519 26.2527C141.017 26.2527 140.169 24.3537 140.169 22.1441V14.9883H142.712V21.9975C142.712 23.2899 143.119 24.1092 144.681 24.1092C146.115 24.1092 146.668 23.5026 146.668 21.8495V14.9883H149.177V21.7014C149.177 24.8136 147.433 26.2527 144.519 26.2527Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M159.389 26.041L156.8 21.2757C156.344 20.4246 155.953 19.5723 155.658 18.8534C155.707 19.6714 155.741 20.7854 155.741 21.832V26.041H153.297V14.988H156.555L158.966 19.3608C159.455 20.2608 159.909 21.2255 160.301 22.0791C160.22 21.1277 160.187 19.6714 160.187 18.6235V14.988H162.631V26.041H159.389Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M170.415 17.0687C169.992 17.0687 169.65 17.0687 169.374 17.0991V23.8624C169.586 23.8955 169.928 23.9285 170.318 23.9285C172.061 23.9285 173.364 22.8489 173.364 20.4913C173.364 18.41 172.696 17.0687 170.415 17.0687ZM170.089 26.1235C168.934 26.1235 167.841 26.0747 166.832 26.0258V15.0217C167.695 14.9728 169.097 14.9226 170.58 14.9226C173.886 14.9226 176.085 16.3644 176.085 20.2918C176.085 24.0766 173.674 26.1235 170.089 26.1235Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M179.537 26.041V14.988H185.726V17.1328H182.078V19.3278H185.531V21.4065H182.078V23.846H185.726V26.041H179.537Z"
    fill="#3C3C3B"
  />
  <path
    fill-rule="evenodd"
    clip-rule="evenodd"
    d="M193.838 17.1664V26.0402H191.314V17.1664H188.673V14.9873H196.476V17.1664H193.838Z"
    fill="#3C3C3B"
  />
</svg>

<a
  (mouseenter)="hover.set(true)"
  (mouseleave)="hover.set(false)"
  class="weblink"
  href="https://www.fagforbundet.no"
  title="Til Fagforbundets startside"
>
  <ff-ngx-icon name="30x30/home" [size]="20" role="presentation" />

  <span>Til fagforbundet.no</span>
</a>
